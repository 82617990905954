<template>
  <div>
    <b-modal ref="modal-clock-time" centered hide-footer :title="title">
      <b-container>
        <b-row class="mb-2">
          <b-col cols="2" class="font-weight-bold">
            Now:
          </b-col>
          <b-col>{{ date }} {{ time }}</b-col>
        </b-row>

        <b-row class="mb-2">
          <b-col cols="2" class="font-weight-bold">
            Name:
          </b-col>
          <b-col v-if="!user">{{
            `${this.profile.data.first_name} ${this.profile.data.last_name}`
          }}</b-col>
          <b-col v-if="user">{{ `${this.user.full_name}` }}</b-col>
        </b-row>
        <b-row class="mb-2">
          <b-col cols="2" class="font-weight-bold">
            Location:
          </b-col>
          <b-col>
            <b-badge :variant="location === 'offsite' ? 'danger' : ''">
              {{ location }}
            </b-badge>
          </b-col>
        </b-row>

        <b-row class="mb-2">
          <b-col cols="2" class="font-weight-bold">
            Date:
          </b-col>
          <b-col>
            <date-range-picker-custom
              id="date-picker"
              default-range="Today"
              :min-date="minDate"
              v-model="selected.date"
              :single-date-picker="true"
              @input="onDateRangeUpdate"
              :ranges="{}"
            />
          </b-col>
        </b-row>
        <b-row class="mb-2">
          <b-col cols="2" class="font-weight-bold">
            Time:
          </b-col>
          <b-col>
            <vue-timepicker
              :minute-interval="5"
              :hour-range="hoursRange"
              :minute-range="minutesRange"
              format="HH:mm:ss"
              v-model="selected.time"
              lazy
              @open="selected.changed = true"
            />
          </b-col>
        </b-row>
        <b-row class="mb-2" v-if="location === 'offsite'">
          <b-col cols="2" class="font-weight-bold">
            Notes:
          </b-col>
          <b-col cols="10">
            <inline-input
              :value="notes"
              @changed="onNotesChanged"
              label="Notes"
              :hide-label="true"
              :readonly="false"
              :mode="FORMCONTROLMODE.EDIT"
              :required="true"
              :is-text-area="true"
            />
          </b-col>
        </b-row>
      </b-container>

      <hr />
      <div class="form-row d-flex  justify-content-end">
        <b-button
          :variant="saveButton.color"
          class="m-1"
          @click="save()"
          :disabled="isLoading"
        >
          <font-awesome-icon v-if="!isLoading" :icon="saveButton.icon" />
          <b-spinner v-if="isLoading" small type="grow" />
          {{ saveButton.title }}
        </b-button>
        <b-button variant="outline-dark" class="m-1" @click="close()">
          <font-awesome-icon icon="ban" /> Close
        </b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import moment from "moment";

import InlineInput from "@/components/InlineInput";

import DateRangePickerCustom from "@/components/DateRangePickerCustom";

import VueTimepicker from "vue2-timepicker/src/vue-timepicker.vue";

import { FORMCONTROLMODE } from "@/shared/constants.js";

import { TRACK_TIME_ACTIONS } from "@/shared/constants";

import { mapState, mapActions } from "vuex";

export default {
  name: "ClockTimeDialog",
  components: {
    DateRangePickerCustom,
    VueTimepicker,
    InlineInput
  },

  props: [],
  data: () => {
    return {
      FORMCONTROLMODE: FORMCONTROLMODE,
      isLoading: false,
      nowUTC: "",
      nowClient: "",
      track_id: "",
      user: {
        id: "",
        full_name: ""
      },
      notes: "",
      isTimeTracking: false,
      action: undefined,
      saveButton: {
        title: "Save",
        color: "success",
        icon: "save"
      },
      hoursRange: [[0, 23]],
      minutesRange: [[0, 59]],
      selected: {
        date: "",
        time: "",
        changed: false
      },
      ip: "",
      location: ""
    };
  },
  computed: {
    ...mapState({
      profile: state => state.profile,
      timeTracker: state => state.timeTracker
    }),
    minDate() {
      return moment()
        .subtract(14, "day")
        .format("YYYY-MM-DD");
    },
    title() {
      if (this.action === TRACK_TIME_ACTIONS.CLOCK_IN) return "Clock In";
      if (this.action === TRACK_TIME_ACTIONS.CLOCK_OUT) return "Clock Out";
      if (this.action === TRACK_TIME_ACTIONS.LUNCH) return "Lunch 30 min";

      return "";
    },
    date() {
      return moment(this.nowClient).format("YYYY-MM-DD");
    },
    time() {
      return moment(this.nowClient).format("HH:mm:ss");
    }
  },
  created: async function() {
    this.getLocation();

    //let timerID = setInterval(this.updateTime, 1000);
    this.updateTime();
    //let self = this
  },
  methods: {
    ...mapActions("timeTracker", ["clockIn", "clockOut", "lunch"]),
    getLocation: async function() {
      try {
        this.ip = await this.$api.get("user/ip");
      } catch (e) {
        console.log(e);
      }

      this.location =
        this.ip !== process.env.VUE_APP_OFFICE_IP ? "offsite" : "4241";
    },
    onNotesChanged(field, value) {
      this.notes = value;
    },
    onDateRangeUpdate() {
      this.selected.changed = true;
    },
    updateTime() {
      this.nowUTC = moment().format("YYYY-MM-DD HH:mm:ss");
      this.nowClient = moment
        .tz(moment(), moment.tz.guess())
        .format("YYYY-MM-DD HH:mm:ss");

      if (!this.selected.changed) {
        this.selected.date = this.date;
        this.selected.time = this.time;
      }
    },

    show(_action, _date) {
      let clockIn = "",
        clockOut = "";

      this.updateTime();

      this.selected.changed = false;

      //if _date is emtpy then update time each second until it won't be changed manually
      if (_date) {
        //selected day

        //stop update date time by timer woth today's date
        this.selected.changed = true;

        //update to passed date
        this.selected.date = _date.day.format("YYYY-MM-DD");

        //this.selected.time = _date.day.format('HH:mm:ss')
        //this.selected.time = this.time

        this.track_id = _date.openTrackId;

        if (_date.user) this.user = _date.user;
        //get time track
        let track = _date.details.find(t => t.action !== "Lunch");

        clockIn = track ? track.time1 : "";
        clockOut = track ? track.time2 : "";
      } else {
        //current day

        this.track_id = !this.$_.isEmpty(this.timeTracker.data)
          ? this.timeTracker.data.trackid
          : "";

        clockIn = !this.$_.isEmpty(this.timeTracker.data)
          ? this.timeTracker.data.tracktime
          : "";
        clockOut = !this.$_.isEmpty(this.timeTracker.data)
          ? this.timeTracker.data.trackouttime
          : "";
      }
      //this.track_id = !_track_id ? (this.timeTracker.data ? this.timeTracker.data.trackid : '') : _track_id

      if (
        _action === TRACK_TIME_ACTIONS.LUNCH ||
        _action === TRACK_TIME_ACTIONS.CLOCK_OUT
      ) {
        //forbid to add lunch later than 30 minutes before clockout time
        if (_action === TRACK_TIME_ACTIONS.LUNCH && clockOut) {
          clockOut = moment(clockOut, "HH:mm:ss")
            .subtract(moment.duration(30, "minutes"))
            .format("HH:mm:ss");
        }

        let startH = clockIn ? clockIn.split(":")[0] : 0;

        //20200630
        //let startH = clockIn ? parseInt(clockIn.split(':')[0])+1 : 0
        //if(startH > 23) startH = 23

        let endH = clockOut ? clockOut.split(":")[0] : 23;

        //20200630
        //let startM = clockIn ? clockIn.split(':')[1] : 0
        //let endM = clockOut ? clockOut.split(':')[1] : 59

        let startM = 0;
        let endM = 59;

        this.hoursRange = [[startH, endH]];
        this.minutesRange = [[startM, endM]];

        if (_action === TRACK_TIME_ACTIONS.LUNCH)
          this.selected.time = moment(clockIn, "HH:mm:ss").format("HH:mm:ss");
      }

      this.action = _action;

      this.$refs["modal-clock-time"].show();
    },
    save() {
      if (!this.$form.testForm(this)) {
        this.$form.makeToastError("Form contains errors");

        return;
      }

      let self = this;
      this.isLoading = true;

      let payload = {
        track_id: this.track_id,
        user_id: this.user.id ? this.user.id : this.profile.data.id,
        date: this.selected.date,
        time: this.selected.time,
        location: this.location,
        notes: this.notes
      };

      if (this.action === TRACK_TIME_ACTIONS.CLOCK_OUT) {
        payload.action = this.action;

        this.clockOut(payload)
          .then(response => {
            self.isLoading = false;

            if (response.status && response.status !== 200)
              throw response.statusText;
            if (response.message !== "success") throw response.message;

            self.$emit("save");

            self.$form.makeToastInfo(response.message);

            self.$refs["modal-clock-time"].hide();

            //allow to update time by timer
            self.selected.changed = false;
          })
          .catch(error => {
            self.isLoading = false;

            self.$form.makeToastError(error);

            return false;
          });
      }

      if (this.action === TRACK_TIME_ACTIONS.CLOCK_IN) {
        payload.action = this.action;

        this.clockIn(payload)
          .then(response => {
            self.selected.changed = false;

            self.isLoading = false;

            self.$emit("save");

            self.$form.makeToastInfo(response.message);

            self.$refs["modal-clock-time"].hide();
          })
          .catch(error => {
            self.isLoading = false;
            self.$form.makeToastError(error.message);
          });
      }

      if (this.action === TRACK_TIME_ACTIONS.LUNCH) {
        payload.action = this.action;
        payload.notes = this.notes;
        payload.location = this.location;

        payload.date1 = this.selected.date;
        payload.time1 = this.selected.time;

        let lunchEnd = moment(
          this.selected.date + " " + this.selected.time
        ).add(30, "minutes");

        payload.date2 = lunchEnd.format("YYYY-MM-DD");
        payload.time2 = lunchEnd.format("HH:mm:ss");

        this.lunch(payload)
          .then(response => {
            self.isLoading = false;

            self.$emit("save");

            self.$form.makeToastInfo(response.message);

            self.$refs["modal-clock-time"].hide();
          })
          .catch(error => {
            self.isLoading = false;
            self.$form.makeToastError(error.message);
          });
      }
    },

    close: function() {
      this.$refs["modal-clock-time"].hide();
    }
  },
  watch: {
    action: function() {
      if (this.action === TRACK_TIME_ACTIONS.CLOCK_IN) {
        this.saveButton.title = "Clock In";
        this.saveButton.color = "success";
        this.saveButton.icon = "sign-in-alt";
      }
      if (this.action === TRACK_TIME_ACTIONS.CLOCK_OUT) {
        this.saveButton.title = "Clock Out";
        this.saveButton.color = "danger";
        this.saveButton.icon = "sign-out-alt";
      }
      if (this.action === TRACK_TIME_ACTIONS.LUNCH) {
        this.saveButton.title = "Lunch";
        this.saveButton.color = "warning";
        this.saveButton.icon = "utensils";
      }
    }
  }
};
</script>

<style scoped>
::v-deep .vue__time-picker input.display-time {
  border: 1px solid #e8e8e8;
  border-radius: 5px;
  color: #5c6873;
}
</style>
