var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('b-modal', {
    ref: "modal-clock-time",
    attrs: {
      "centered": "",
      "hide-footer": "",
      "title": _vm.title
    }
  }, [_c('b-container', [_c('b-row', {
    staticClass: "mb-2"
  }, [_c('b-col', {
    staticClass: "font-weight-bold",
    attrs: {
      "cols": "2"
    }
  }, [_vm._v(" Now: ")]), _c('b-col', [_vm._v(_vm._s(_vm.date) + " " + _vm._s(_vm.time))])], 1), _c('b-row', {
    staticClass: "mb-2"
  }, [_c('b-col', {
    staticClass: "font-weight-bold",
    attrs: {
      "cols": "2"
    }
  }, [_vm._v(" Name: ")]), !_vm.user ? _c('b-col', [_vm._v(_vm._s("".concat(this.profile.data.first_name, " ").concat(this.profile.data.last_name)))]) : _vm._e(), _vm.user ? _c('b-col', [_vm._v(_vm._s("".concat(this.user.full_name)))]) : _vm._e()], 1), _c('b-row', {
    staticClass: "mb-2"
  }, [_c('b-col', {
    staticClass: "font-weight-bold",
    attrs: {
      "cols": "2"
    }
  }, [_vm._v(" Location: ")]), _c('b-col', [_c('b-badge', {
    attrs: {
      "variant": _vm.location === 'offsite' ? 'danger' : ''
    }
  }, [_vm._v(" " + _vm._s(_vm.location) + " ")])], 1)], 1), _c('b-row', {
    staticClass: "mb-2"
  }, [_c('b-col', {
    staticClass: "font-weight-bold",
    attrs: {
      "cols": "2"
    }
  }, [_vm._v(" Date: ")]), _c('b-col', [_c('date-range-picker-custom', {
    attrs: {
      "id": "date-picker",
      "default-range": "Today",
      "min-date": _vm.minDate,
      "single-date-picker": true,
      "ranges": {}
    },
    on: {
      "input": _vm.onDateRangeUpdate
    },
    model: {
      value: _vm.selected.date,
      callback: function callback($$v) {
        _vm.$set(_vm.selected, "date", $$v);
      },
      expression: "selected.date"
    }
  })], 1)], 1), _c('b-row', {
    staticClass: "mb-2"
  }, [_c('b-col', {
    staticClass: "font-weight-bold",
    attrs: {
      "cols": "2"
    }
  }, [_vm._v(" Time: ")]), _c('b-col', [_c('vue-timepicker', {
    attrs: {
      "minute-interval": 5,
      "hour-range": _vm.hoursRange,
      "minute-range": _vm.minutesRange,
      "format": "HH:mm:ss",
      "lazy": ""
    },
    on: {
      "open": function open($event) {
        _vm.selected.changed = true;
      }
    },
    model: {
      value: _vm.selected.time,
      callback: function callback($$v) {
        _vm.$set(_vm.selected, "time", $$v);
      },
      expression: "selected.time"
    }
  })], 1)], 1), _vm.location === 'offsite' ? _c('b-row', {
    staticClass: "mb-2"
  }, [_c('b-col', {
    staticClass: "font-weight-bold",
    attrs: {
      "cols": "2"
    }
  }, [_vm._v(" Notes: ")]), _c('b-col', {
    attrs: {
      "cols": "10"
    }
  }, [_c('inline-input', {
    attrs: {
      "value": _vm.notes,
      "label": "Notes",
      "hide-label": true,
      "readonly": false,
      "mode": _vm.FORMCONTROLMODE.EDIT,
      "required": true,
      "is-text-area": true
    },
    on: {
      "changed": _vm.onNotesChanged
    }
  })], 1)], 1) : _vm._e()], 1), _c('hr'), _c('div', {
    staticClass: "form-row d-flex justify-content-end"
  }, [_c('b-button', {
    staticClass: "m-1",
    attrs: {
      "variant": _vm.saveButton.color,
      "disabled": _vm.isLoading
    },
    on: {
      "click": function click($event) {
        return _vm.save();
      }
    }
  }, [!_vm.isLoading ? _c('font-awesome-icon', {
    attrs: {
      "icon": _vm.saveButton.icon
    }
  }) : _vm._e(), _vm.isLoading ? _c('b-spinner', {
    attrs: {
      "small": "",
      "type": "grow"
    }
  }) : _vm._e(), _vm._v(" " + _vm._s(_vm.saveButton.title) + " ")], 1), _c('b-button', {
    staticClass: "m-1",
    attrs: {
      "variant": "outline-dark"
    },
    on: {
      "click": function click($event) {
        return _vm.close();
      }
    }
  }, [_c('font-awesome-icon', {
    attrs: {
      "icon": "ban"
    }
  }), _vm._v(" Close ")], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }