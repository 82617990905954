<template>
  <div class="animated fadeIn">
    <b-card>
      <b-card-body>
        <b-row>
          <b-col>
            <filtering-panel
              ref="filteringPanel"
              mode="client"
              dataset-name="time-tracker-active-users3"
              :hide-custom-filters="true"
              :hide-search-button="false"
              :show-labels="false"
              :compact="true"
              :loaded="filteringPanel.loaded"
              :filters="filteringPanel.filters"
              :load-dictionaries="loadDictionaries"
              @change="onFilteringPanelChange"
              @dict-loaded="onFilteringPanelLoad"
              @search="onFilteringPanelSearch"
              @reset="onFilteringPanelReset"
              @loaded="onFilteringPanelLoad"
            />
          </b-col>
        </b-row>
        <hr />
        <b-row>
          <b-col>
            <table-custom
              ref="requests"
              :name="`custom_table_active_users`"
              :loading="isLoading"
              :data="dataTable.dataSet"
              :options="dataTable.options"
            >
              <div slot="custom-actions" slot-scope="props">
                <div class="btn-group">
                  <b-button
                    v-if="!props.row['Clock Out']"
                    variant="outline-dark"
                    @click="
                      clockTimeDialog(
                        $constants.TRACK_TIME_ACTIONS.CLOCK_OUT,
                        props.row
                      )
                    "
                    title="Clock Out"
                  >
                    <font-awesome-icon icon="sign-out-alt" />
                  </b-button>
                </div>
              </div>
            </table-custom>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
    <clock-time-dialog ref="clockTimeDialog" @save="onDialogSave" />
  </div>
</template>

<script>
import FilteringPanel from "@/components/FilteringPanel";
import TableCustom from "@/components/TableCustom";
import ClockTimeDialog from "@/components/ClockTimeDialog";

import moment from "moment";

export default {
  name: "ActiveUsers",
  components: {
    FilteringPanel,
    TableCustom,
    ClockTimeDialog
  },
  data: function() {
    return {
      isLoading: false,
      rawData: {},
      filteringPanel: {
        loaded: false,
        selected: {},
        filters: [
          {
            type: "select",
            dataType: "string",
            title: "User Name",
            name: "user_name",
            trackby: "id",
            label: "label",
            options: [],
            selected: {},
            multiple: true
          },

          {
            type: "select",
            dataType: "string",
            title: "Role",
            name: "role_name",
            trackby: "id",
            label: "label",
            options: [],
            selected: {},
            multiple: true
          },
          {
            type: "daterange",
            dataType: "datetime",
            defaultRange: "This month",
            title: "Period",
            name: "period"
          }
        ]
      },

      dataTable: {
        loading: false,
        dataSet: [],
        options: {
          columns: [
            "ID",
            "User Name",
            "Clock In",
            "Clock Out",
            "Time worked",
            "Lunch hours",
            "Relevant hours",
            "Day of week",
            "Role",
            "Actions"
          ],
          filterable: [
            "ID",
            "User Name",
            "Clock In",
            "Clock Out",
            "Time worked",
            "Lunch hours",
            "Relevant hours",
            "Day of week",
            "Role"
          ],
          formatColumns: [
            {
              name: "Available (h)",
              style: {
                style: "decimal"
              }
            }
          ],
          footerHeadings: false,
          perPage: 50,
          showCustomActions: true
        }
      }
    };
  },
  computed: {},
  mounted() {},
  methods: {
    async loadDictionaries() {
      let self = this;

      const roles = async () => {
        if (this.filteringPanel.filters.find(f => f.name === "role_name")) {
          this.$api.get("roles").then(response => {
            this.filteringPanel.filters.find(
              f => f.name === "role_name"
            ).options = [
              ...response.map(u => ({
                id: u.roleid,
                label: u.rolename
              }))
            ];
          });
        }
      };

      const users = async () => {
        if (this.filteringPanel.filters.find(f => f.name === "user_name")) {
          this.$api.get("dictionaries/users").then(response => {
            this.filteringPanel.filters.find(
              f => f.name === "user_name"
            ).options = [
              ...response.map(u => ({
                id: u.id,
                label: u.full_name
              }))
            ];
          });
        }
      };
      Promise.all([users(), roles()]).then(() => {
        self.filteringPanel.loaded = true;
      });
    },
    onFilteringPanelChange(e) {
      this.filteringPanel.selected = e;

      this.filterData(e);
    },
    onFilteringPanelSearch(e) {
      this.filteringPanel.selected = e;

      this.getData();
    },
    onFilteringPanelReset() {
      this.$refs.filteringPanel.resetFilters({
        resetStorage: true
      });
    },
    onFilteringPanelLoad() {
      this.getData();
    },
    onDialogSave() {
      this.getData();
    },
    viewItem() {},

    filterData: function(e) {
      this.filteringPanel.selected = e;

      if (!this.rawData.length) return;

      let filteredData = this.rawData;

      let selectedRoles = this.filteringPanel.selected.role_name
        ? this.filteringPanel.selected.role_name.map(i => i.label)
        : [];

      filteredData = filteredData.filter(i =>
        selectedRoles.length && i["Role"]
          ? selectedRoles.includes(i["Role"])
          : true
      );

      let selectedUsers = this.filteringPanel.selected.user_name
        ? this.filteringPanel.selected.user_name.map(i => i.label)
        : [];

      filteredData = filteredData.filter(i =>
        this.filteringPanel.selected.user_name &&
        this.filteringPanel.selected.user_name.length &&
        i["User Name"]
          ? selectedUsers.includes(i["User Name"])
          : true
      );

      this.dataTable.dataSet = filteredData;
    },
    clockTimeDialog(action, row) {
      let payload = {
        day: moment(),
        details: [],
        openTrackId: row.ID,
        user: {
          id: row["User ID"],
          full_name: row["User Name"]
        }
      };

      this.$refs.clockTimeDialog.show(action, payload);
    },
    async getData() {
      let filter = this.$refs.filteringPanel.selected;

      let data = {
        period: filter["period"],
        users: filter.user_name,
        roles: filter.role_name
      };

      let response = await this.$api.post("timetracker/active-users", data);

      //let url = "timetracker/active-users";
      //let response = await this.$api.post(url);

      this.rawData = response;

      this.isLoading = false;

      this.dataTable.dataSet = this.rawData;

      this.filterData(filter);
      /*
      this.filteringPanel.filters.find(
        f => f.name === "user_name"
      ).options = this.$helpers.getDistinctArray(
        this.rawData,
        "User Name",
        "User Name"
      );
      this.filteringPanel.filters.find(
        f => f.name === "status"
      ).options = this.$helpers.getDistinctArray(
        this.rawData,
        "Status",
        "Status"
      );
      */
    }
  },
  watch: {}
};
</script>

<style></style>
