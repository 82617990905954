var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "animated fadeIn"
  }, [_c('b-card', [_c('b-card-body', [_c('b-row', [_c('b-col', [_c('filtering-panel', {
    ref: "filteringPanel",
    attrs: {
      "mode": "client",
      "dataset-name": "time-tracker-active-users3",
      "hide-custom-filters": true,
      "hide-search-button": false,
      "show-labels": false,
      "compact": true,
      "loaded": _vm.filteringPanel.loaded,
      "filters": _vm.filteringPanel.filters,
      "load-dictionaries": _vm.loadDictionaries
    },
    on: {
      "change": _vm.onFilteringPanelChange,
      "dict-loaded": _vm.onFilteringPanelLoad,
      "search": _vm.onFilteringPanelSearch,
      "reset": _vm.onFilteringPanelReset,
      "loaded": _vm.onFilteringPanelLoad
    }
  })], 1)], 1), _c('hr'), _c('b-row', [_c('b-col', [_c('table-custom', {
    ref: "requests",
    attrs: {
      "name": "custom_table_active_users",
      "loading": _vm.isLoading,
      "data": _vm.dataTable.dataSet,
      "options": _vm.dataTable.options
    },
    scopedSlots: _vm._u([{
      key: "custom-actions",
      fn: function fn(props) {
        return _c('div', {}, [_c('div', {
          staticClass: "btn-group"
        }, [!props.row['Clock Out'] ? _c('b-button', {
          attrs: {
            "variant": "outline-dark",
            "title": "Clock Out"
          },
          on: {
            "click": function click($event) {
              return _vm.clockTimeDialog(_vm.$constants.TRACK_TIME_ACTIONS.CLOCK_OUT, props.row);
            }
          }
        }, [_c('font-awesome-icon', {
          attrs: {
            "icon": "sign-out-alt"
          }
        })], 1) : _vm._e()], 1)]);
      }
    }])
  })], 1)], 1)], 1)], 1), _c('clock-time-dialog', {
    ref: "clockTimeDialog",
    on: {
      "save": _vm.onDialogSave
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }